var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('h4',{staticClass:"pt-4"},[_vm._v("Turmas")]),_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mb-0",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","width":"100%"},on:{"click":_vm.create}},[_vm._v("Nova Turma")])],1),_c('v-col',{attrs:{"cols":"4","offset":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar por turma ou professor","color":"white","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":[
              {
                text: 'Todos',
                value: 'All',
              },
              {
                text: 'Matutino',
                value: 'MORNING',
              },
              {
                text: 'Vespertino',
                value: 'EVENING',
              },
              {
                text: 'Noturno',
                value: 'NOCTURNAL',
              },
              {
                text: 'Integral',
                value: 'INTEGRAL',
              },
            ],"label":"Faixa Etária","color":"white","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.searchSession),callback:function ($$v) {_vm.searchSession=$$v},expression:"searchSession"}})],1)],1),_c('v-data-table',{staticClass:"no-line-row",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [10, 30, 50, 100] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("empty")(item.name))+" ")]}},{key:`item.session`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("empty")(_vm.periodSessionType[item.session]))+" ")]}},{key:`item.ageGroup`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("empty")(_vm.ageGroupType[item.ageGroup]))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","title":"Editar"},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","color":"error","title":"Remover"},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1),_c('modal-register-student-class',{ref:"modalRegister",on:{"success":_vm.fetch}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }