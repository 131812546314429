import api from './api';

class StudentClassService {
  get(id) {
    return api.get(`/student-classes/${id}`).then((res) => res.data);
  }

  find(params) {
    return api.get('/student-classes', { params }).then((res) => res.data);
  }

  create(data) {
    return api.post(`/student-classes`, data).then((res) => res.data);
  }

  update(id, data) {
    return api.patch(`/student-classes/${id}`, data).then((res) => res.data);
  }

  delete(id) {
    return api.delete(`/student-classes/${id}`).then((res) => res.data);
  }
}

export default new StudentClassService();
