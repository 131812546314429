<script>
import StudentClassService from '@/service/student-classes.service';
import { PeriodSessionType } from '@/utils/enums/PeriodSessionType';
import { AgeGroupType } from '@/utils/enums/AgeGroupType';

export default {
  name: 'list-student-class',

  components: {
    ModalRegisterStudentClass: () => import('./ModalRegisterStudentClass.vue'),
  },

  data() {
    return {
      loading: false,
      periodSessionType: PeriodSessionType,
      ageGroupType: AgeGroupType,
      searchSession: 'All',
      options: {
        sortBy: ['name'],
        sortDesc: [false],
        search: '',
      },

      totalItems: 0,
      items: [],

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Turma', value: 'name' },
        { text: 'Período', sortable: false, value: 'session' },
        { text: 'Faixa etária', sortable: false, value: 'ageGroup' },
        { text: 'Professores Responsáveis', sortable: false, value: 'teacherNames' },
        { text: 'Total de alunos', sortable: false, value: 'students.length' },
        { text: 'Ações', align: 'right', sortable: false, value: 'actions' },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },

      deep: true,
    },

    searchSession: {
      handler() {
        this.fetch();
      },
    },
  },

  methods: {
    async fetch() {
      this.loading = true;

      try {
        const filter = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          search: this.options.search,
        };

        if (this.searchSession && this.searchSession !== 'All') {
          filter['filter.session'] = `$eq:${this.searchSession}`;
        }

        if (this.options.sortBy.length > 0) {
          filter.sortBy = `${this.options.sortBy[0]}:${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`;
        }

        const { data, meta } = await StudentClassService.find(filter);
        this.items = data?.map((item) => {
          return {
            ...item,
            teacherNames: item.teachers?.map((teacher) => teacher.name).join(', '),
          };
        });
        this.page = meta?.currentPage;
        this.totalItems = meta?.totalItems;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    create() {
      this.$refs.modalRegister.$emit('create');
    },

    edit(item) {
      this.$refs.modalRegister.$emit('edit', item.id);
    },

    remove(payload) {
      this.$swal({
        text: `Deseja remover turma '${payload.name}'?`,
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return StudentClassService.delete(payload.id);
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Removido!',
              text: 'Turma removida com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetch();
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <h4 class="pt-4">Turmas</h4>
    <v-card>
      <v-card-text>
        <v-row class="mb-0" align="center">
          <v-col cols="2">
            <v-btn color="primary" width="100%" @click="create">Nova Turma</v-btn>
          </v-col>
          <v-col cols="4" offset="4">
            <v-text-field
              v-model="options.search"
              append-icon="mdi-magnify"
              label="Pesquisar por turma ou professor"
              color="white"
              hide-details="auto"
              outlined
              dense
            />
          </v-col>
          <v-col cols="2" class="text-right">
            <v-select
              v-model="searchSession"
              :items="[
                {
                  text: 'Todos',
                  value: 'All',
                },
                {
                  text: 'Matutino',
                  value: 'MORNING',
                },
                {
                  text: 'Vespertino',
                  value: 'EVENING',
                },
                {
                  text: 'Noturno',
                  value: 'NOCTURNAL',
                },
                {
                  text: 'Integral',
                  value: 'INTEGRAL',
                },
              ]"
              label="Faixa Etária"
              color="white"
              hide-details="auto"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [10, 30, 50, 100] }"
          class="no-line-row"
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name | empty }}
          </template>

          <template v-slot:[`item.session`]="{ item }">
            {{ periodSessionType[item.session] | empty }}
          </template>

          <template v-slot:[`item.ageGroup`]="{ item }">
            {{ ageGroupType[item.ageGroup] | empty }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon title="Editar" @click="edit(item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon color="error" title="Remover" @click="remove(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <modal-register-student-class ref="modalRegister" @success="fetch" />
  </div>
</template>
